<template>
    <div>
        <h3 class="title">合约模板(下载)</h3>
        <!-- 查询区域 -->
        <div class="table-page-search-wrapper">
            <a-form layout="inline" @keyup.enter.native="searchQuery">
                <a-row :gutter="24">
                    <a-col :md="6" :sm="6">
                        <a-form-item label="分类(大类)">
                            <a-input v-model="queryParams.category" @blur="searchCust"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="6">
                        <a-form-item label="附件名称">
                            <a-input v-model="queryParams.name" @blur="searchCust"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :md="6" :sm="6">
                        <a-button @click="searchCust" type="primary" icon="search" style="margin-right:10px;">搜索</a-button>
                        <a-button @click="searchReset" type="primary" icon="reload">刷新</a-button>
                    </a-col>
                </a-row>
            </a-form>
        </div>
        <a-table
            ref="table"
            size="middle"
            :scroll="{x:true}"
            bordered
            rowKey="id"
            :columns="columns"
            :dataSource="dataSource"
            :pagination="ipagination"
            :loading="loading"
            class="j-table-force-nowrap"
            @change="handleTableChange">
        </a-table>
    </div>
</template>

<script>
import {
    getContactTemp
} from '@/api/api'
import {preIcon} from '@/utils/util'
export default {
    name:'pcReport',
    components:{
    },
    data() {
        return {
            loading:false,
            // 表头
            columns: [
                {
                    title: '#',
                    dataIndex: '',
                    key:'rowIndex',
                    width:60,
                    align:"center",
                    customRender:function (t,r,index) {
                    return parseInt(index)+1;
                    }
                },
                {
                    title:'分类(大类)',
                    align:"center",
                    dataIndex: 'category'
                },
                {
                    title:'模板',
                    align:"center",
                    dataIndex: 'name',
                    customRender:(text,record) => {
                    return (<a href={record.url}>{text}</a>)
                    }
                },
            ],
            dataSource:[],
            ipagination: {
                current: 1,
                pageSize: 10,
                pageSizeOptions: ['10', '20', '30'],
                showTotal: (total, range) => {
                return range[0] + '-' + range[1] + ' 共' + total + '条'
                },
                showQuickJumper: true,
                showSizeChanger: true,
                total: 0
            },
            queryParams:{
                name:'',
                category:''
            }
        }
    },
    created() {
        this.initData();
    },
    methods:{
        initData() {
            const {category,name} = this.queryParams
            let temp = {
                category:category?preIcon(category):'',
                name:name?preIcon(name):'',
            } 
            let params = {
                pageNo:this.ipagination.current,
                pageSize:this.ipagination.pageSize,
                ...temp
            }
            this.loading = true;
            getContactTemp(params)
            .then(res => {
                if(res.success) {
                    this.dataSource = res.result.records;
                    this.ipagination.total = res.result.total;
                }
            }).finally(() => {
                console.log('===========结束')
                this.loading = false;
            })
        },
        handleTableChange(pagination) {
            //分页变化时触发
            this.ipagination = pagination
            this.initData()
        },
        searchCust() {
            this.initData();
        },
        searchReset() {
            this.queryParams = {
                category:'',
                name:''
            };
            this.initData();
        }

    }
}
</script>

<style lang="less" scoped>
.title {
    text-align: center;
    padding: 5px 0;
    background-color:#002b45 ;
    color: #fff;
}
/** [表格主题样式一] 表格强制列不换行 */
.j-table-force-nowrap {
    td, th {
        white-space: nowrap;
    }

    .ant-table-selection-column {
        padding: 12px 22px !important;
    }

    /** 列自适应，弊端会导致列宽失效 */
    &.ant-table-wrapper .ant-table-content {
        overflow-x: auto;
    }
}
.table-page-search-wrapper {
    margin-left:10px;
    .ant-form-inline {
        .ant-form-item {
            display: flex;
            margin-bottom: 24px;
            margin-right: 0;
            .ant-form-item-control-wrapper {
                flex: 1 1;
                display: inline-block;
                vertical-align: middle;
            }
            > .ant-form-item-label {
                line-height: 32px;
                padding-right: 8px;
                width: auto;
            }
            .ant-form-item-control {
                height: 32px;
                line-height: 32px;
            }
        }
    }
    .table-page-search-submitButtons {
        display: block;
        margin-bottom: 24px;
        white-space: nowrap;
    }

}
</style>